import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from 'environments/environment';
import { User, Role } from 'app/auth/models';
import { ToastrService } from 'ngx-toastr';
import { CookieService } from 'app/services/auth/cookie.service';
import { OAuth } from 'app/services/auth/user.model';
import { Company } from 'app/main/account/connect/connect.model';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
  //public
  public currentUser: Observable<OAuth>;

  //private
  private currentUserSubject: BehaviorSubject<User>;

  /**
   *
   * @param {HttpClient} _http
   * @param {ToastrService} _toastrService
   */
  constructor(private http: HttpClient, private _toastrService: ToastrService, private cookieService: CookieService) {
    // this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
    let user:User = this.getOnlyUser();
    this.currentUserSubject = new BehaviorSubject<User>(user);
    this.currentUser = this.currentUserSubject.asObservable();
  }

  // getter: currentUserValue
  public get currentUserValue(): OAuth {
    return this.currentUserSubject.value;
  }

  /**
   *  Confirms if user is admin
   */
  get isAdmin() {
    return this.currentUser && this.currentUserSubject.value.role === Role.Admin;
  }

  /**
   *  Confirms if user is client
   */
  get isClient() {
    return this.currentUser && this.currentUserSubject.value.role === Role.Client;
  }

  /**
   * User logout
   *
   */
  logout() {
    // remove user from local storage to log user out
    // localStorage.removeItem('currentUser');
    this.clearActiveUser();
    // notify
    this.currentUserSubject.next(null);
  }

   /**
     * Performs the auth
     * @param email email of user
     * @param password password of user
     */
    login(email: string, password: string) {

        return this.http.post<any>(`${environment.apiUrl}/api/login`, { email, password })
          .pipe(map(user => {
              // login successful if there's a jwt token in the response
  
              console.log("Looger :::", user.data.token, JSON.stringify(user), user.data)
              if (user.data && user.data.token) {
                  //store user data into cookies
                  this.setJWTToken(user.data)
                  this.setActiveUser(JSON.stringify(user.data));
                  this.setActiveUserID(user.data)
                  this.setIdentifier(user.data)

                  this.currentUserSubject.next(user.data);
              }
              return user;
          }));

      }
  
      /**
       * Performs the auth
       * @param email email of user
       * @param password password of user
       */
     signup(name: string, email: string, phone: string, password: string ) {
      
      return this.http.post<any>(`${environment.apiUrl}/api/signup`, { name, email, phone, password })
          .pipe(map(user => {
              // login successful if there's a jwt token in the response
  
              // console.log("Looger :::", JSON.stringify(user))
              if (user.data && user.data.token) {
                  //store user data into cookies
                  this.setJWTToken(user.data)
                  this.setActiveUser(JSON.stringify(user.data));
                  this.setActiveUserID(user.data)
                  this.setIdentifier(user.data)
              }
              return user.data;
          }));
      }

    /**
     * Performs the auth
     * @param email email of user
     * @param password password of user
     */
    forgot(email: string) {
    
      return this.http.post<any>(`${environment.apiUrl}/api/wpsd/forgot`, { email })
          .pipe(map(data => {
              // login successful if there's a jwt token in the response
              if(data.status == "success"){
                return data.data
              }
              return data
          }));
      }

    /**
     * Performs the auth
     * @param email email of user
     * @param password password of user
     */
    reset(pin:string, identifier:string, reset_type:string, new_password:string, confirm_new_password: string ) {
    
      return this.http.post<any>(`${environment.apiUrl}/api/wpsd/reset`, { pin, identifier, reset_type, new_password, confirm_new_password })
          .pipe(map(data => {
              // login successful if there's a jwt token in the response
              return data
          }));
      }
  
      isLoggedIn(){
  
        return !!this.getJWTToken();
      }
  
      getJWTToken(){
  
        return this.cookieService.getCookie("feramy_rms_auth_token");
      }
  
      setJWTToken(user: any){
  
        console.log("Active User::: ", user)
        this.cookieService.setCookie("feramy_rms_auth_token", user.token, 1);
      }
  
      setIdentifier(user: any){
  
        this.cookieService.setCookie("feramy_rms_auth_identifier", user.user.identifier, 1);
      }
  
      getIdentifier(){
  
        return this.cookieService.getCookie("feramy_rms_auth_identifier");
      }

      isVerifiedUser(){

        let user:User = this.getOnlyUser();
        return !!user._state
      }

      isUsersOnTrial(){

        try{
          let user:any = this.getOnlyUser();
          let company: Company = user.company

          if(company.plan.verified){
              return false;
          }
          return true

        }catch(e){
          return true
        }
      }

      getTrialDaysLeft(){

        try{
              let user:any = this.getOnlyUser();
              let company: Company = user.company
              return company.plan.days || 0
          }catch(e){
            return 0
          }
      }

      getOnlyUser():User
      {
        try{
          let data:OAuth =  this.getActiveUser();
          let user:any = data.user;
          return user;
        }catch(e){

        }
      }
  
      getUser(): User 
      {
        let user:any = this.getActiveUser();
        return user.user;
      }
  
      getActiveUserRole()
      {
        // let user:User = this.getUser();
        //   switch(user.role){
        //     case 0: return "Client" 
        //     case 1: return "Admin" 
        //   }
      }
  
      getActiveUserID():any {
        
        return this.cookieService.getCookie("feramy_rms_auth_id");
      }
  
      setActiveUserID(user: any){
  
        this.cookieService.setCookie("feramy_rms_auth_id", user.user.id, 1);
      }
  
      setActiveUser(user: any){
  
          this.cookieService.setCookie("feramy_rms_auth", user, 1);
      }
  
      getActiveUser(): User
      {
  
        try{
  
            let u:any = this.cookieService.getCookie("feramy_rms_auth");
            let log:any = JSON.parse(u)
            let user: User = log
            return user;
  
        }catch(e){
            return new User;
        }
      }
  
      clearActiveUser(){
  
        // this.cookieService.deleteCookie('feramy_rms_auth');
        // this.cookieService.deleteCookie('feramy_rms_auth_token');
        this.cookieService.setCookie('feramy_rms_auth', '', -1)
        this.cookieService.setCookie('feramy_rms_auth_token', '', -1)
        this.cookieService.setCookie("feramy_rms_auth_identifier", "", -1);
        // this.user = null;
      }
  
      /**
       * A string signed from server containing the user's encrypted information without password
       * Use this as refresh token to get fresh access token, This will be used by the system with the valid access token
       * without access token the server should reject this.
       * The system should go online to refresh the token every 2 hours, 
       * 
       * if the user is active in the last 5 mins, 
       * if not ask if the user want to keep the page or the system should log out automatically, 
       * if no response, the system should logout else the user should remain login and the refresh token method continues every 2 hours
       */
      refreshLogin(){
           
      }
}
