<li ngbDropdown class="nav-item dropdown-notification mr-25">
  <a class="nav-link" ngbDropdownToggle id="navbarNotificationDropdown">
    <i class="ficon" data-feather="bell"></i>
    <!-- <span class="badge badge-pill badge-danger badge-up">
      {{  notifications.messages?.length + notifications.systemMessages?.length }}
    </span> -->
    <span class="badge badge-pill badge-danger badge-up">0</span>
  </a>
  <ul
    ngbDropdownMenu
    aria-labelledby="navbarNotificationDropdown"
    class="dropdown-menu dropdown-menu-media dropdown-menu-right">
    <!-- Notifications header -->
    <!-- <li class="dropdown-menu-header">
      <div class="dropdown-header d-flex">
        <h4 class="notification-title mb-0 mr-auto">Notifications</h4>
        <div class="badge badge-pill badge-light-primary">
          {{ notifications.messages?.length + notifications.systemMessages?.length }} New
        </div>
      </div>
    </li> -->
    <!--/ Notifications header -->

    <!-- Notifications content -->
    
    <!--/ Notifications content -->

    <!-- Notifications footer -->
    <li class="dropdown-menu-footer">
      <a class="btn btn-primary btn-block" href="javascript:void(0)">Read all notifications</a>
    </li>
    <!--/ Notifications footer -->
  </ul>
</li>
