<!-- Not authorized-->
<div class="misc-wrapper">
  <a class="brand-logo" href="javascript:void(0);">
    <!-- <img src="{{ coreConfig.app.appLogoImage }}" alt="brand-logo" height="28" /> -->
    <h2 class="brand-text text-primary ml-1">{{ coreConfig.app.appName }}</h2></a
  >
  <div class="misc-inner p-2 p-sm-3">
    <div class="w-100 text-center">
      <h2 class="mb-1">You are not authorized!</h2>
      <p class="mb-2">
        Please, login to access your account
      </p>
      <a class="btn btn-primary mb-1 btn-sm-block" routerLink="/login" rippleEffect
        >Back to home</a
      ><img
        class="img-fluid"
        [src]="
          coreConfig.layout.skin === 'dark'
            ? 'assets/images/pages/not-authorized-dark.svg'
            : 'assets/images/pages/not-authorized.svg'
        "
        alt="Not authorized page"
      />
    </div>
  </div>
</div>
<!-- / Not authorized-->
