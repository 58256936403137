<!-- Under maintenance-->
<div class="misc-wrapper">
  <a class="brand-logo" href="javascript:void(0);">
    <!-- <img src="{{ coreConfig.app.appLogoImage }}" alt="brand-logo" height="28" /> -->
    <h2 class="brand-text text-primary ml-1">{{ coreConfig.app.appName }}</h2></a
  >
  <div class="misc-inner p-2 p-sm-3">
    <div class="w-100 text-center">
      <h2 class="mb-1">Under Maintenance 🛠</h2>
      <p class="mb-3">Sorry for the inconvenience but we're performing some maintenance at the moment</p>
      <form class="form-inline justify-content-center row m-0 mb-2" action="javascript:void(0);">
        <input
          class="form-control col-12 col-md-5 mb-1 mr-md-2"
          id="notify-email"
          type="text"
          placeholder="john@example.com"
        />
        <button class="btn btn-primary mb-1 btn-sm-block" type="submit" rippleEffect>Notify</button>
      </form>
      <img
        class="img-fluid"
        [src]="
          coreConfig.layout.skin === 'dark'
            ? 'assets/images/pages/under-maintenance-dark.svg'
            : 'assets/images/pages/under-maintenance.svg'
        "
        alt="Under maintenance page"
      />
    </div>
  </div>
</div>
<!-- / Under maintenance-->
