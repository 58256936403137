import { filter } from 'rxjs/operators';
import { CoreMenuService } from './../../../@core/components/core-menu/core-menu.service';
import { AuthenticationService } from './../../auth/service/authentication.service';
import { ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from '@angular/router';

import { BehaviorSubject, Observable } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AccessLevelControlResolver implements Resolve<any> {

    constructor(
        private _authenticationService: AuthenticationService,
        private _coreMenuService: CoreMenuService,
        private router: Router){

    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        
        const currentUser = this._authenticationService.currentUserValue;
        let menu = this._coreMenuService.getCurrentMenu();
        let url = route.parent.routeConfig.path;
        let m = menu.filter(m => {
            return m.id.toLowerCase() == url
        })
        if(m.length == 0){
            this.router.navigate(['/not-authorize']);
            return false;
        }
        if(!m[0].role.includes(currentUser.role)) {
            // role not authorised so redirect to not-authorized page
            this.router.navigate(['/not-authorize']);
            return false;
        }
    }
}