import { Injectable } from "@angular/core"
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor
} from '@angular/common/http'
import { Observable } from "rxjs"
import { AuthenticationService } from "app/auth/service"

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
    constructor( private authService: AuthenticationService){


    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        
        // console.log("Request Interceptor::", this.authService.getJWTToken())

        if(! request.url.endsWith('login')){

            const headers = request.headers.set("Authorization", `Bearer ${this.authService.getJWTToken()}`)
            const newRequest = request.clone({headers})
            return next.handle(newRequest);
        }
        return next.handle(request)
    }
}