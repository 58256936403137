import { Role } from "app/auth/models";

export class User {

    id!: number;
    company_id!: number;
    name!: string;
    email!: string;
    phone!: string;
    outlet_id!: number;
    role_id!:number;
    status!: number;
    identifier!: string;
    _state!: number;
    color!: string
    created_at!: string;
    updated_at!: string;
    role: Role;
}

export class OAuth extends User{
    expires_in!: number;
    token!: string 
    token_type!: string
    user!: OauthUser
    avatar:string =  'avatar-s-11.jpg'
}
export class OauthUser{
    id!: number 
    name!: string 
    identifier!: string 
    is_admin!: number
    company!: Company
}
export class Company
{
    name!: string
    address!: string
    country!: string
    plan!: Plan
}

export class Plan {
    expired!: boolean
    days!: number
    verified!: boolean
}